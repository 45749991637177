import React, { Suspense, lazy } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { PrivateRoute } from "./protected.route";
import CircularProgress from '@material-ui/core/CircularProgress';
// import LogInComponent from "./component/Auth/LoginComponent";

// import DpInformationComponent from "./component/Configuration/DpInformationComponent";
// import BrokerInformationComponent from "./component/Configuration/BrokerInformationComponent";
// import ExchangegrievancecellComponent from "./component/Configuration/ExchangegrievancecellComponent";
// import BankgatewaydetailsComponent from "./component/Configuration/BankgatewaydetailsComponent";
// import LogoutComponent from "./component/Auth/LogoutComponent";
// import FundWithdrawalComponent from "./component/Reports/FundWithdrawalComponent";
// import LoginComponent from "./component/Auth/LoginComponent";
import NotFound from "./component/Utils/NotFound";
import Ipo from "./component/User/ipo";
// import UccConfigurationComponent from "./component/Configuration/UccConfigurationComponent";
// import SMSGatewayInfoComponent from "./component/Configuration/SmsGatewayInfoComponent";
// import EmailGatewayInfoComponent from "./component/Configuration/EmailGatewayInfoComponent";
// import HelpComponent from "./component/Help/HelpComponent";
// import LoginLogoutComponent from "./component/Reports/LoginLogoutComponent";
// import ActivityLogsComponent from "./component/Reports/ActivityLogsComponent";
// import CustomerAuditLogsComponent from "./component/Reports/CustomerAuditLogsComponent";
// import OnboardingUsersComponent from "./component/Reports/OnboardingUsersComponent";
// import ConfigureBillingComponent from "./component/Billing/ConfigureBillingComponent";
// import ViewDownloadBillComponent from "./component/Billing/ViewDownloadBillComponent";
// import DashboardComponent from "./component/Dashboard/DashboardComponent";
// import AddEditUserComponent from "./component/Admin/AddEditUserComponent";
// import ManageRolesComponent from "./component/Admin/ManageRolesComponent";
// import BackofficeconfigurationComponent from "./component/Configuration/BackofficeconfigurationComponent";
// import ExportComponent from "./component/Backofffice/ExportComponent";
// import UserListsComponent from "./component/User/UserListsComponent";
// import ImportComponent from "./component/Backofffice/ImportComponent";

const LogInComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LogInComponent" */ "./component/Auth/LoginComponent"
    )
);

// const SessionLoginComponent = React.lazy(
//   () =>
//     import(
//       /* webpackChunkName: "SessionLoginComponent" */ "./component/Auth/SessionLoginComponent"
//     )
// );

const UserListsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UserListsComponent" */ "./component/User/UserListsComponent"
    )
);

const ExistingListTableComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ExistingListTableComponent" */ "./component/User/ExistingListTableComponent"
    )
);


const IpoComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ExistingListTableComponent" */ "./component/User/IpoComponent"
    )
);

const DpInformationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DpInformationComponent" */ "./component/Configuration/DpInformationComponent"
    )
);

const BrokerInformationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BrokerInformationComponent" */ "./component/Configuration/BrokerInformationComponent"
    )
);

const ExchangegrievancecellComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ExchangegrievancecellComponent" */ "./component/Configuration/ExchangegrievancecellComponent"
    )
);

const BankgatewaydetailsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BankgatewaydetailsComponent" */ "./component/Configuration/BankgatewaydetailsComponent"
    )
);

const BackofficeconfigurationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "BackofficeconfigurationComponent" */ "./component/Configuration/BackofficeconfigurationComponent"
    )
);

const ExportComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ExportComponent" */ "./component/Backofffice/ExportComponent"
    )
);

const ImportComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImportComponent" */ "./component/Backofffice/ImportComponent"
    )
);
const TradeImportComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ImportComponent" */ "./component/Backofffice/TradeImportComponent"
    )
);
const DashboardComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DashboardComponent" */ "./component/Dashboard/DashboardComponent"
    )
);

const AddEditUserComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "AddEditUserComponent" */ "./component/Admin/AddEditUserComponent"
    )
);

const ManageRolesComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ManageRolesComponent" */ "./component/Admin/ManageRolesComponent"
    )
);

const ActivityLogsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ActivityLogsComponent" */ "./component/Reports/ActivityLogsComponent"
    )
);

const CustomerAuditLogsComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "CustomerAuditLogsComponent" */ "./component/Reports/CustomerAuditLogsComponent"
    )
);

const OnboardingUsersComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "OnboardingUsersComponent" */ "./component/Reports/OnboardingUsersComponent"
    )
);

const ConfigureBillingComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ConfigureBillingComponent" */ "./component/Billing/ConfigureBillingComponent"
    )
);

const TransactionSlabComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "TransactionSlabComponent" */ "./component/Billing/TransactionSlabComponent"
    )
);

const ViewDownloadBillComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ViewDownloadBillComponent" */ "./component/Billing/ViewDownloadBillComponent"
    )
);

const UccConfigurationComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "UccConfigurationComponent" */ "./component/Configuration/UccConfigurationComponent"
    )
);

const SMSGatewayInfoComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SmsGatewayInfoComponent" */ "./component/Configuration/SmsGatewayInfoComponent"
    )
);

const EmailGatewayInfoComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "EmailGatewayInfoComponent" */ "./component/Configuration/EmailGatewayInfoComponent"
    )
);

const ChangePasswordComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangePasswordComponent" */ "./component/Configuration/ChangePasswordComponent"
    )
);

const HelpComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "HelpComponent" */ "./component/Help/HelpComponent"
    )
);

const LoginLogoutComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginLogoutComponent" */ "./component/Reports/LoginLogoutComponent"
    )
);

const LogoutComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LogoutComponent" */ "./component/Auth/LogoutComponent"
    )
);
const FundWithdrawalComponent = React.lazy(
  () =>
    import(
      /* webpackChunkName: "FundWithdrawalComponent" */ "./component/Reports/FundWithdrawalComponent"
    )
);

const routes = (
  <Suspense fallback={<div className="loadingMain"><CircularProgress className="loaderE" /></div>}>
    
    <Switch>
      <Route exact path="/login" component={LogInComponent}></Route>
      {/* <Route exact path="/session" component={SessionLoginComponent}></Route> */}
      <PrivateRoute
        exact
        path="/"
        component={UserListsComponent}
      ></PrivateRoute>
            <PrivateRoute
        exact
        path="/ExistingListTable"
        component={ExistingListTableComponent}
      ></PrivateRoute>
                  <PrivateRoute
        exact
        path="/ipo"
        component={IpoComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/logout"
        component={LogoutComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/uccinfo"
        component={UccConfigurationComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/dashboard"
        component={DashboardComponent}
      ></PrivateRoute>
      {/* <PrivateRoute
      exact
      path="/userlists"
      component={UserListsComponent}
    ></PrivateRoute> */}
      <PrivateRoute
        exact
        path="/addedituser"
        component={AddEditUserComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/manageroles"
        component={ManageRolesComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/export"
        component={ExportComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/import"
        component={ImportComponent}
      ></PrivateRoute>
            <PrivateRoute
        exact
        path="/TradeImport"
        component={TradeImportComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/activitylogs"
        component={ActivityLogsComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/onboardingusers"
        component={OnboardingUsersComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/customerauditlogs"
        component={CustomerAuditLogsComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/configurebilling"
        component={ConfigureBillingComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/transactionslab"
        component={TransactionSlabComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/viewdownload"
        component={ViewDownloadBillComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/brokerinfo"
        component={BrokerInformationComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/uccconfig"
        component={UccConfigurationComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/dpinfo"
        component={DpInformationComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/grievancecell"
        component={ExchangegrievancecellComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/backofficeconfig"
        component={BackofficeconfigurationComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/bankgateway"
        component={BankgatewaydetailsComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/smsgateway"
        component={SMSGatewayInfoComponent}
      ></PrivateRoute>
      <PrivateRoute
        exact
        path="/emailgateway"
        component={EmailGatewayInfoComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/changepassword"
        component={ChangePasswordComponent}
      ></PrivateRoute>

      <PrivateRoute
        exact
        path="/loginlogout"
        component={LoginLogoutComponent}
      ></PrivateRoute>
      <Route
        exact
        path="/fundwithdrawal"
        component={FundWithdrawalComponent}
      ></Route>

      <PrivateRoute exact path="/help" component={HelpComponent}></PrivateRoute>
      <PrivateRoute path="*" component={NotFound} />
    </Switch>
  </Suspense>
);

export default routes;
