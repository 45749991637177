import {
  FETCH_DP_INFO_DATA,
  FETCH_DP_INFO_DATA_SUCCESS,
  FETCH_DP_INFO_DATA_ERROR,
} from "../actionTypes";

const istate = {
  fetchingDpInfo: false,
  fetchedDpInfo: false,
  dpData: [],
};

export default function dpInformationReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_DP_INFO_DATA: {
      return {
        ...state,
        fetchingDpInfo: true,
        fetchedDpInfo: false,
      };
    }
    case FETCH_DP_INFO_DATA_SUCCESS: {
      //  console.log(action.payload, "sdfsdfsd");
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingDpInfo: false,
        fetchedDpInfo: true,
        dpData: action.payload,
      };
    }
    case FETCH_DP_INFO_DATA_ERROR: {
      return {
        ...state,
        fetchedDpInfo: false,
        brokerInfoError: action.payload,
      };
    }

    default:
      return state;
  }
}
