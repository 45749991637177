import axios from "axios";
import React from "react";
import { Route, Redirect } from "react-router-dom";

export function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}
export function isLoggedIn() {
  const user = localStorage.getItem("username");
  const brokerId = localStorage.getItem("brokerId");
  const auth_token = localStorage.getItem("auth_token");

  if (user && brokerId) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token;
    return true;
  }
  return false;
}
