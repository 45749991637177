import {
  FETCH_GRIEVANCE_INFO_DATA,
  FETCH_GRIEVANCE_INFO_DATA_SUCCESS,
  FETCH_GRIEVANCE_INFO_DATA_ERROR,
} from "../actionTypes";

const istate = {
  fetchingGrievanceInfo: false,
  fetchedGrievanceInfo: false,
  grievanceInfo: [],
};

export default function grievanceInfoReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_GRIEVANCE_INFO_DATA: {
      return {
        ...state,
        fetchingGrievanceInfo: true,
        fetchedGrievanceInfo: false,
      };
    }
    case FETCH_GRIEVANCE_INFO_DATA_SUCCESS: {
      // console.log(action.payload, "sdfsdfsd");
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingGrievanceInfo: false,
        fetchedGrievanceInfo: true,
        grievanceInfo: action.payload,
      };
    }
    case FETCH_GRIEVANCE_INFO_DATA_ERROR: {
      return {
        ...state,
        fetchedGrievanceInfo: false,
        grievanceInfoError: action.payload,
      };
    }

    default:
      return state;
  }
}
