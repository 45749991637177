import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import List from "@material-ui/core/List";

import IconDashboard from "@material-ui/icons/Dashboard";
import IconShoppingCart from "@material-ui/icons/ShoppingCart";
import IconPeople from "@material-ui/icons/People";
import IconBarChart from "@material-ui/icons/BarChart";
import IconLibraryBooks from "@material-ui/icons/LibraryBooks";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AppMenuItem from "./AppMenuItem";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ReceiptIcon from "@material-ui/icons/Receipt";
import BusinessIcon from "@material-ui/icons/Business";
import FaceIcon from "@material-ui/icons/Face";
import SettingsApplicationsIcon from "@material-ui/icons/SettingsApplications";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import SettingsIcon from "@material-ui/icons/Settings";
const appMenuItems = [
  // {
  //   name: "Dashboard",
  //   link: "/dashboard",
  //   Icon: IconDashboard,
  // },
  {
    name: "Onboarding",
    link: "/",
    Icon: InfoOutlinedIcon,
  },

  // {
  //   name: "Manage User",
  //   Icon: FaceIcon,
  //   items: [
  //     {
  //       name: "Add/Edit User",
  //       link: "/addedituser",
  //     },
  //     // {
  //     //   name: "Manage Roles",
  //     //   link: "/manageroles",
  //     // },
  //   ],
  // },
  {
    name: "Back Office",
    Icon: BusinessIcon,
    items: [
      {
        name: "Export",
        link: "/export",
        Icon: ArrowUpwardIcon,
      },
      {
        name: "Import",
        link: "/import",
        Icon: ArrowDownwardIcon,
      },
    ],
  },
  // {
  //   name: "Upload New user",
  //   link: "/TradeImport",
  //   Icon: ArrowDownwardIcon,
  // },
  {
    name: "Existing User",
    link: "/ExistingListTable",
    Icon: ArrowDownwardIcon,
  },
  // {
  //   name: "IPO",
  //   link: "/ipo",
  //   Icon: ArrowDownwardIcon,
  // },
  {
    name: "Reports",
    Icon: IconBarChart,
    items: [
      // {
      //   name: "Login/Logout",
      //   link: "/loginlogout",
      // },
      // {
      //   name: "Activity Logs",
      //   link: "/activitylogs",
      // },
      // {
      //   name: "Onboarding Users",
      //   link: "/onboardingusers",
      // },
      // {
      //   name: "Customer Audit Logs",
      //   link: "/customerauditlogs",
      // },
      {
        name: "Fund Withdrawal",
        link: "/fundwithdrawal",
        Icon: AccountBalanceWalletIcon,
      },
    ],
  },
  // {
  //   name: "Billing",
  //   Icon: ReceiptIcon,
  //   items: [
  //     {
  //       name: "Configure billing",
  //       link: "/configurebilling",
  //     },
  //     {
  //       name: "Transaction Slab",
  //       link: "/transactionslab",
  //     },
  //     {
  //       name: "View/Download Bill",
  //       link: "/viewdownload",
  //     },
  //   ],
  // },
  {
    name: "Configuration",
    Icon: SettingsIcon,
    items: [
      {
        name: "Broker Info",
        link: "/brokerinfo",
        Icon: InfoOutlinedIcon,
      },
      {
        name: "UCC Info",
        link: "/uccinfo",
        Icon: InfoOutlinedIcon,
      },
      {
        name: "DP Info",
        link: "/dpinfo",
        Icon: InfoOutlinedIcon,
      },
      {
        name: "Exchange Grievance Cell",
        link: "/grievancecell",
        Icon: InfoOutlinedIcon,
      },

      {
        name: "Change Password",
        link: "/changepassword",
        Icon: InfoOutlinedIcon,
      },

      // {
      //   name: "Backoffice Configuration",
      //   link: "/backofficeconfig",
      // },
      // {
      //   name: "Bank Gateway Details",
      //   link: "/bankgateway",
      // },
      // {
      //   name: "SMS gateway info",
      //   link: "/smsgateway",
      // },
      // {
      //   name: "Email gateway info",
      //   link: "/emailgateway",
      // },
    ],
  },
  {
    name: "Help",
    link: "/help",
    Icon: HelpOutlineIcon,
  },
  {
    name: "Logout",
    link: "/logout",
    Icon: ExitToAppIcon,
  },
];

const AppMenu: React.FC = () => {
  const classes = useStyles();

  return (
    <List component="nav" className={classes.appMenu} disablePadding>
      {/* <AppMenuItem {...appMenuItems[0]} /> */}
      {appMenuItems.map((item, index) => (
        <AppMenuItem {...item} key={index} />
      ))}
    </List>
  );
};

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appMenu: {
      width: "100%",
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: "#97c05c",
    },
  })
);

export default AppMenu;
