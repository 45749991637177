export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const FETCH_USER_LIST_DATA_SUCCESS = "FETCH_USER_LIST_DATA_SUCCESS";
export const FETCH_USER_LIST_DATA_ERROR = "FETCH_USER_LIST_DATA_ERROR";
export const FETCH_USER_LIST_DATA = "FETCH_USER_LIST_DATA";

export const FETCH_POST_LIST = "FETCH_POST_LIST";
export const FETCH_POST_LIST_SUCCESS = "FETCH_POST_LIST_SUCCESS";
export const FETCH_POST_LIST_FAILURE = "FETCH_POST_LIST_FAILURE";

export const POSTING_DATA_SUCCESS = "POSTING_DATA_SUCCESS";
export const POSTING_DATA_FAILURE = "POSTING_DATA_FAILURE";

export const DELETE_POST_SUCCESS = "DELETE_POST_SUCCESS";
export const DELETE_POST_FAILURE = "DELETE_POST_FAILURE";

export const FETCH_CATEGORY_LIST_DATA = "FETCH_CATEGORY_LIST_DATA";
export const FETCH_CATEGORY_LIST_DATA_SUCCESS =
  "FETCH_CATEGORY_LIST_DATA_SUCCESS";
export const FETCH_CATEGORY_LIST_DATA_ERROR = "FETCH_CATEGORY_LIST_DATA_ERROR";

export const CATEGORY_CHANGE = "CATEGORY_CHANGE";
export const CATEGORY_CHANGE_SUCCESS = "CATEGORY_CHANGE_SUCCESS";
export const CATEGORY_CHANGE_FAILURE = "CATEGORY_CHANGE_FAILURE";

export const FALSE_SHOW_MESSAGE = "FALSE_SHOW_MESSAGE";
export const SHOW_MESSAGE_SUCCESS = "SHOW_MESSAGE_SUCCESS";

export const FETCH_BROKER_INFO_DATA = "FETCH_BROKER_INFO_DATA";
export const FETCH_BROKER_INFO_DATA_SUCCESS = "FETCH_BROKER_INFO_DATA_SUCCESS";
export const FETCH_BROKER_INFO_DATA_ERROR = "FETCH_BROKER_INFO_DATA_ERROR";

export const FETCH_DP_INFO_DATA = "FETCH_DP_INFO_DATA";
export const FETCH_DP_INFO_DATA_SUCCESS = "FETCH_DP_INFO_DATA_SUCCESS";
export const FETCH_DP_INFO_DATA_ERROR = "FETCH_DP_INFO_DATA_ERROR";
export const FETCH_FUNDWITHDRAWL_DATA = "FETCH_FUNDWITHDRAWL_DATA";
export const FETCH_FUNDWITHDRAWL_DATA_SUCCESS =
  "FETCH_FUNDWITHDRAWL_DATA_SUCCESS";
export const FETCH_FUNDWITHDRAWL_DATA_ERROR = "FETCH_FUNDWITHDRAWL_DATA_ERROR";

export const FETCH_GRIEVANCE_INFO_DATA = "FETCH_GRIEVANCE_INFO_DATA";
export const FETCH_GRIEVANCE_INFO_DATA_SUCCESS =
  "FETCH_GRIEVANCE_INFO_DATA_SUCCESS";
export const FETCH_GRIEVANCE_INFO_DATA_ERROR =
  "FETCH_GRIEVANCE_INFO_DATA_ERROR";
