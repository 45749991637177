import * as React from "react";
import { Link } from "react-router-dom";
class NotFound extends React.Component<any, any> {
  render() {
    return (
      <div className="not-found">
        <h3>404 - Not Found</h3>
        <p>
          The page you are looking for does not exist.
          <Link to="/">Go to Home </Link>
        </p>
      </div>
    );
  }
}
export default NotFound;
