import {
  FETCH_BROKER_INFO_DATA,
  FETCH_BROKER_INFO_DATA_SUCCESS,
  FETCH_BROKER_INFO_DATA_ERROR,
} from "../actionTypes";

const istate = {
  fetchingBrokerInfo: false,
  fetchedBrokerInfo: false,
  brokerData: [],
};

export default function brokerInformationReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_BROKER_INFO_DATA: {
      return {
        ...state,
        fetchingBrokerInfo: true,
        fetchedBrokerInfo: false,
      };
    }
    case FETCH_BROKER_INFO_DATA_SUCCESS: {
      // console.log(action.payload, "sdfsdfsd");
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingBrokerInfo: false,
        fetchedBrokerInfo: true,
        brokerData: action.payload,
      };
    }
    case FETCH_BROKER_INFO_DATA_ERROR: {
      return {
        ...state,
        fetchedBrokerInfo: false,
        brokerInfoError: action.payload,
      };
    }

    default:
      return state;
  }
}
