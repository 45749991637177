import React, { ReactElement, useEffect, useState } from "react";
import ApiService from "../../api/ApiService";
import { withRouter } from "react-router-dom";
import { IconButton, Toolbar } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import clsx from "clsx";
import AppBar from "@material-ui/core/AppBar";
import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
const drawerWidth = 270;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menuButton: {
      marginRight: 36,
    },
    hide: {
      display: "none",
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  })
);

function HeaderComponent(props: any): ReactElement {
  const classes = useStyles();
  // const theme = useTheme();
  const [brokerUrl, setBrokerUrl] = useState("");
  const [menu, setMenu] = useState(false);

  const handleSidebarMobile = () => {
    let element = document.getElementById("sidebar");
    let width = window.innerWidth;
    if (width < 768) {
      if (element !== null) {
        element.classList.toggle("show");
        setMenu(!menu);
      }
    }
  };
  window.addEventListener("resize", function (event) {
    let width = window.innerWidth;

    let element = document.getElementById("sidebar");
    if (element !== null && element.classList.contains("show")) {
      if (width > 767) {
        element.classList.remove("show");
      }
    }
  });

  useEffect(() => {
    let brokerId = localStorage.getItem("brokerId");
    if (brokerId !== null && brokerId) {
      ApiService.fetchBrokerInfo(brokerId).then((response) => {
        if (response.data.body.data[0] !== undefined) {
          if (response.data.body.data[0].brokerLogoUrl) {
            //  alert("test");
            setBrokerUrl(response.data.body.data[0].brokerLogoUrl);
          }
        }
      });
    }
  }, [props.location.pathname, brokerUrl]);

  if (
    props.location.pathname === "/login" ||
    props.location.pathname === "/session"
  ) {
    return <> </>;
  }

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
      })}
    >
      <Toolbar>
        <div className="mobileHideDesktopHamburger">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={props.handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: props.open,
            })}
          >
            <MenuIcon />
          </IconButton>
        </div>
        <div className="mobileHamburger">
          <IconButton
            color="inherit"
            onClick={handleSidebarMobile}
            edge="start"
          >
            {menu ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
        </div>

        <div className="logoB">
          {brokerUrl && brokerUrl !== "" ? (
            <img src={brokerUrl} alt="Broker Logo" />
          ) : null}
        </div>
        <div className="logo">
          <p>Powered by</p>
          <img
            src="https://www.invest19.com/images/logo/invest19_logo-blue.svg"
            alt="logo"
          />
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default withRouter(HeaderComponent);
