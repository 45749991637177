import { combineReducers } from "redux";

import userListsReducer from "./reducers/userLists.reducer";
import userLoginReducer from "./reducers/userLogin.reducer";
import brokerInformationReducer from "./reducers/brokerInfo.reducer";
import grievanceInfoReducer from "./reducers/grievanceInfo.reducer";
import dpInformationReducer from "./reducers/dpInfo.reducer";
import fundWithDrawlReducer from "./reducers/fundwithdrwal.reducer";
const rootReducer = combineReducers({
  userListsReducer,
  userLoginReducer,
  brokerInformationReducer,
  grievanceInfoReducer,
  dpInformationReducer,
  fundWithDrawlReducer,
});

export default rootReducer;
