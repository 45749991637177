import {
  FETCH_USER_LIST_DATA,
  FETCH_USER_LIST_DATA_SUCCESS,
  FETCH_USER_LIST_DATA_ERROR,
  FALSE_SHOW_MESSAGE,
} from "../actionTypes";

const istate = {
  fetchingUserList: false,
  fetchedUserList: false,
  allData: [],
  message: "",
  statusCode: "",
  showMessage: false,
  totalRecord: "",
  searchRecord: "",
};

export default function userListsReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_USER_LIST_DATA: {
      return {
        ...state,
        fetchingUserList: true,
        fetchedUserList: false,
      };
    }
    case FETCH_USER_LIST_DATA_SUCCESS: {
      console.log(action.payload.applicationList);
      return {
        ...state,
        //columns: Object.keys(action.payload.columns[0]),
        fetchingUserList: false,
        fetchedUserList: true,
        allData: action.payload.applicationList,
        totalRecord: action.payload.totalRecord,
      };
    }
    case FETCH_USER_LIST_DATA_ERROR: {
      return {
        ...state,
        fetchedUserList: false,
        userListDataError: action.payload,
      };
    }

    default:
      return state;
  }
}
