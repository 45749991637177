import React from "react";
import "./App.scss";
import { BrowserRouter as Router } from "react-router-dom";
import WrapperComponent from "./component/Layout/WrapperComponent";
import { browserHistory } from "react-router";

const App: React.FC = (props: any) => {
  return (
    <div className="App">
      <Router>
        <WrapperComponent />
      </Router>
    </div>
  );
};

export default App;
