import {
  FETCH_FUNDWITHDRAWL_DATA,
  FETCH_FUNDWITHDRAWL_DATA_SUCCESS,
  FETCH_FUNDWITHDRAWL_DATA_ERROR,
} from "../actionTypes";

const istate = {
  fetchingfundWithDrawlData: false,
  fetchedfundWithDrawlData: false,
  fundWithDrawlData: [],
};

export default function fundWithDrawlReducer(state = istate, action) {
  switch (action.type) {
    case FETCH_FUNDWITHDRAWL_DATA: {
      return {
        ...state,
        fetchingfundWithDrawlData: true,
        fetchedfundWithDrawlData: false,
      };
    }
    case FETCH_FUNDWITHDRAWL_DATA_SUCCESS: {
      // console.log(action.payload, "sdfsdfsd");
      return {
        ...state,
        fetchingfundWithDrawlData: false,
        fetchedfundWithDrawlData: true,
        fundWithDrawlData: action.payload,
      };
    }
    case FETCH_FUNDWITHDRAWL_DATA_ERROR: {
      return {
        ...state,
        fetchedfundWithDrawlData: false,
        fundWithDrawlError: action.payload,
      };
    }
    default:
      return state;
  }
}
