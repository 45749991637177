import React from "react";
import SidebarComponent from "./SidebarComponent";
import HeaderComponent from "./HeaderComponent";
import ContentComponent from "./ContentComponent";
import FooterComponent from "./FooterComponent";

// const drawerWidth = 310;

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       display: "flex",
//     },
//   })
// );

function WrapperComponent() {
  const [open, setOpen] = React.useState(false);
  // const [openn, setOpenn] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <>
      <div className="mainSection">
        <HeaderComponent handleDrawerOpen={handleDrawerOpen} open={open} />
        <div className="sidebarSection" id="sidebar">
          <SidebarComponent handleDrawerClose={handleDrawerClose} open={open} />
        </div>
        <ContentComponent />
      </div>
      <FooterComponent />
    </>
  );
}
export default WrapperComponent;
