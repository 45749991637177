import Axios from "axios";
import axios from "axios";
import { Console } from "console";

const USER_API_BASE_URL = "https://gatewayapi.invest19.com/";


const USER_API_BASE_URL_WITHOUT_HEROKU = "https://gatewayapi.invest19.com/";

// const USER_API_BASE_URL =
//   "https://cors-anywhere.herokuapp.com/https://gatewayapi-uat.invest19.com/";

// const USER_API_BASE_URL_WITHOUT_HEROKU = "https://gatewayapi-uat.invest19.com/";

const APPROVE_API_ENDPOINT = "aof/save/update/actions";
const BROKER_LIST = "aof/find/aof/info";
const LOGIN_END_POINT = "user/auth/broker/login";
const FIND_BROKER_INFO = "aof/find/broker/details/by";
const FIND_EXCHANGE_GRIEVANCE = "aof/find/exchange/grievance/info/";
const FIND_DP_INFO = "aof/find/broker/dp/info";
const FUND_WITHDRAWL = "wallet/user/get/withdraw/fund";
const EXPORT = "aof/backoffice/clientdetails/xml/download";
const UCC = "aof/find/ucc/info";
const UPDATE_STAGES = "aof/update/user/stages";
const BOW_ID_UPDATE = "aof/save/map/userId/bowId";
const SEARCH = "aof/find/application/form/by";

const token = localStorage.getItem('auth_token');
Axios.defaults.headers.common['Authorization'] = `Bearer ${token} ' '`;


// new user

const NEW_USER_UPLOAD = "aof/upload/broker/existing/user";



export const CSV_DOWNLOAD = `${USER_API_BASE_URL_WITHOUT_HEROKU}aof/find/generate/aof/csv/`;

class ApiService {
  SearchData(brokerId, searchText) {
    const token = localStorage.getItem('auth_token');
    return axios.post(
      `${USER_API_BASE_URL}${SEARCH}/${brokerId}/${searchText}`, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }

  fetchAllData(id, pagefrom, pageto) {
    const token = localStorage.getItem('auth_token');
    return axios.get(
      `${USER_API_BASE_URL_WITHOUT_HEROKU}${BROKER_LIST}/${id}?page_from=${pagefrom}&page_to=${pageto}`, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }


    // Existing user data get list
  fetchAllExistingData(id, pagefrom, pageto) {
    const token = localStorage.getItem('auth_token');
    return axios.get(
      `${USER_API_BASE_URL_WITHOUT_HEROKU}${BROKER_LIST}/${id}?page_from=${pagefrom}&page_to=${pageto}&isInvest19User=false`, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }
  // Existing user data get list



  addUpdateBow(data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(`${USER_API_BASE_URL}${BOW_ID_UPDATE}`, data, ({ headers: {Authorization : `Bearer ${token}`}}));
  }

  UpdateStage(brokerId, clientId, notes, data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(
      `${USER_API_BASE_URL}${UPDATE_STAGES}/${brokerId}/${clientId}/${notes}`,
      data, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }

  ApproveData(brokerId, clientId, data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(
      `${USER_API_BASE_URL}${APPROVE_API_ENDPOINT}/${brokerId}/${clientId}`,
      data, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }

  RejectData(brokerId, clientId, data) {
    const token = localStorage.getItem('auth_token');
    return axios.post(
      `${USER_API_BASE_URL}${APPROVE_API_ENDPOINT}/${brokerId}/${clientId}`,
      data, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }

  brokerLogin(values) {
    const token = localStorage.getItem('auth_token');
    return axios.post(
      `${USER_API_BASE_URL}${LOGIN_END_POINT}?adminId=${values.userId}&adminPassword=${values.password}`, ({ headers: {Authorization : `Bearer ${token}`}})
    );
  }

  fetchBrokerInfo(id) {
    const token = localStorage.getItem('auth_token');
    return axios.get(`${USER_API_BASE_URL}${FIND_BROKER_INFO}/${id}`, ({ headers: {Authorization : `Bearer ${token}`}}));
  }

  fetchDpInfo(id) {
    const token = localStorage.getItem('auth_token');
    return axios.get(`${USER_API_BASE_URL}${FIND_DP_INFO}/${id}`, ({ headers: {Authorization : `Bearer ${token}`}}));
  }

  fetchGrievanceInfo() {
    const token = localStorage.getItem('auth_token');
    return axios.get(USER_API_BASE_URL + FIND_EXCHANGE_GRIEVANCE, ({ headers: {Authorization : `Bearer ${token}`}}));
  }

  fetchFundWithdrwal(id) {
    const token = localStorage.getItem('auth_token');
    return axios.get(`${USER_API_BASE_URL}${FUND_WITHDRAWL}/${id}`, ({ headers: {Authorization : `Bearer ${token}`}}));
  }

  exportAPI(brokderId) {
    const token = localStorage.getItem('auth_token');
    return `${USER_API_BASE_URL_WITHOUT_HEROKU}${EXPORT}/${brokderId}`;
  }

  fetchUccData(id) {
    const token = localStorage.getItem('auth_token');
    return axios.get(`${USER_API_BASE_URL}${UCC}/${id}`, ({ headers: {Authorization : `Bearer ${token}`}}));
  }

// New user
  UploadNewUserData(brokderId) {
    const token = localStorage.getItem('auth_token');
    return axios.post(`${USER_API_BASE_URL}${NEW_USER_UPLOAD}/${brokderId}`, ({ headers: {Authorization : `Bearer ${token}`}}));
  }



}

export default new ApiService();
